<template>
    <div class="flex flex-column flex-auto">
        <div class="px-5 pt-5 pb-0">
            <div id="asset-page" class="grid">
                <!-- Top row -->
                <div class="col-12 pb-0">
                    <div class="grid">
                        <!-- Piracy since activation -->
                        <PiracySinceActivation :allSourcesData="allSourcesData" :allSourcesTotal="allSourcesTotal"/>

                        <!-- Asset poster box -->
                        <AssetPosterBox :currentAssetId="currentAssetId" :currentAssetName="currentAssetName" :currentAssetDate="currentAssetDate"
                                        :currentAssetImage="currentAssetImage" :currentAssetType="currentAssetType" :currentAssetSubtype="currentAssetSubtype"
                                        :currentAssetActive="currentAssetActive" :currentAssetAssetHandle="currentAssetAssetHandle"/>
                    </div>
                </div>

                <!-- Second row -->
                <div class="col-12">
                    <div class="grid">
                        <!-- Info Hash Distribution -->
                        <div class="col-12 xl:col-8">
                            <InfohashDistribution ref="infohashDistribution" choropleth-height="600"/>
                        </div>

                        <!-- Doughnuts -->
                        <DoughnutStack class="col-12 xl:col-4" :totalTakedownDoughnutData="totalTakedownDoughnutData" :totalTakedownDoughnutOptions="totalTakedownDoughnutOptions"
                                       :cyberlockerDoughnutData="cyberlockerDoughnutData" :cyberlockerDoughnutOptions="cyberlockerDoughnutOptions"
                                       :socialsDoughnutData="socialsDoughnutData" :socialsDoughnutOptions="socialsDoughnutOptions"/>

                    </div>
                </div>

                <div id="pdf-name" class="col-12 py-0 my-0 flex flex-wrap justify-content-end" style="visibility: hidden">
                    <p class="py-0 my-0 text-600">
                        © Cyber Theft Watch Ltd. {{ new Date().getFullYear() }} All Rights Reserved
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useRoute} from 'vue-router';
import globals from "@/globals";

import PiracySinceActivation from "@/components/graphs/asset/PiracySinceActivation";
import AssetPosterBox from "@/components/graphs/asset/AssetPosterBox";
import InfohashDistribution from "@/components/graphs/asset/InfohashDistribution";
import DoughnutStack from "@/components/graphs/asset/DoughnutStack";


export default {
    name: "AssetInformation",

    components: {
        PiracySinceActivation,
        AssetPosterBox,
        InfohashDistribution,
        DoughnutStack
    },

    data: function() {
        return {
            requestMade: false,
            currentAssetId: 0,
            currentAssetName: "",
            currentAssetDate: "",
            currentAssetImage: "",
            currentAssetType: "",
            currentAssetSubtype: "",
            currentAssetActive: "",
            currentAssetAssetHandle: "",

            allSourcesGraphOptions: {
                adaptive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        labels : {
                            boxHeight: 2,
                            boxWidth: 30,
                        }
                    },
                },
                scales:{
                    x: {
                        ticks: {
                            maxRotation: 0,
                            // callback: function(value) {
                            //     console.log(value);
                            //     return '$' + value;
                            // }
                        }
                    },
                    y: {
                        beginAtZero: true,
                    }
                },
                elements: {
                    point: {
                        pointRadius: 0,
                    },
                    line: {
                        tension: 0.4,
                        borderWidth: 2,
                    },
                },
                layout: {
                    padding: {
                        top: 3,
                        bottom: 3
                    }
                }
            },

            allSourcesData: {},
            searchEnginesData: {},
            searchEnginesDiff: "",
            cyberlockerData: {},
            cyberlockerDiff: "",
            torrentData: {},
            torrentDiff: "",
            socialsData: {},
            socialsDiff: "",

            allSourcesTotal: 0,
            allSourcesTotals: {},
            totalTakedownDoughnutOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        maxWidth: 150,
                        labels: {
                            usePointStyle: true,
                        },
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: function(context) {
                                let option = context.label;
                                let label = option.replace(/\s+$/, '') + ": " + context.formattedValue || '';
                                return label;
                            }
                        }
                    },
                },
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: false,
                    }
                },
                layout: {
                    padding: {
                        left: 0
                    }
                }
            },
            cyberlockerDoughnutOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        maxWidth: 150,
                        labels: {
                            usePointStyle: true,
                        },
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: function(context) {
                                let option = context.label;
                                let label = option.replace(/\s+$/, '') + ": " + context.formattedValue || '';
                                return label;
                            }
                        }
                    },
                },
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: false,
                    }
                },
                layout: {
                    padding: {
                        left: 0
                    }
                }
            },
            socialsDoughnutOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        maxWidth: 150,
                        labels: {
                            usePointStyle: true,
                        },
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: function(context) {
                                let option = context.label;
                                let label = option.replace(/\s+$/, '') + ": " + context.formattedValue || '';
                                return label;
                            }
                        }
                    },
                },
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: false,
                    }
                },
                layout: {
                    padding: {
                        left: 0
                    }
                }
            },
            totalTakedownDoughnutData: {labels:[], datasets:[{data:[]}]},
            cyberlockerDoughnutData: {labels:[], datasets:[{data:[]}]},
            socialsDoughnutData: {labels:[], datasets:[{data:[]}]},

            infohashChoroplethData: {},

            isStaff: false,

            items: [
                { menu: 'Export png' },
            ],
        }
    },

    mounted() {
        this.currentAssetId = this.$route.params.assetId;
        this.getAssetData();
        this.getGraphData();
        this.isStaff = this.$store.getters.isStaff;

        this.$emitter.on('asset-created', () => {
            console.debug("Asset created, refreshing data");
            this.getAssetData();
            this.getGraphData();
            this.isStaff = this.$store.getters.isStaff;
        });
    },


    methods: {
        getAssetData() {
            let allAssets = this.$store.getters.getActiveAssetData.concat(this.$store.getters.getInactiveAssetData)
            for (let i in allAssets) {
                if (this.currentAssetId === allAssets[i].id) {
                    let currentAsset = allAssets[i];
                    this.currentAssetName = currentAsset.name;
                    this.currentAssetDate = null;
                    this.currentAssetImage = currentAsset.image;
                    this.currentAssetType = currentAsset.type;
                    this.currentAssetSubtype = currentAsset.subtype;
                    this.currentAssetActive = currentAsset.active;
                    this.currentAssetAssetHandle = currentAsset.assetHandle;
                    return;
                }
            }
        },

        getGraphData() {
            if (this.requestMade) {
                console.debug("Avoiding duplicate request to get graph data.")
                return;
            }
            // Get all data and parse it for all graphs on this page
            const requestOptions = {
                method: "GET",
                credentials: 'include',
                headers: new Headers({
                    "Content-Type": "application/json",
                    "XSRF-TOKEN": this.$store.getters.getCSRFToken,
                }),
            };

            console.debug("Current asset id is ", this.currentAssetId);

            let params = new URLSearchParams({
                assetid: this.currentAssetId,
            })

            let uri = globals.host + "/asset/getdata?" + params.toString();

            // Get from DB
            fetch(uri, requestOptions)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    console.debug(data);
                    let average = 0;

                    // Total calculation
                    this.allSourcesTotal = data.allsources.datasets
                        .reduce((a, b) =>
                            a + b.data.reduce((a, b) => a + b, 0), 0
                        );

                    // Data for most graphs
                    this.currentAssetDate = data.startdate;
                    this.allSourcesData = data.allsources;
                    this.totalTakedownDoughnutData = data.allsourcesdoughnut;
                    this.cyberlockerDoughnutData = data.cyberlockerdoughnut;
                    this.socialsDoughnutData = data.socialsdoughnut;
                    this.infohashChoroplethData = data.infohashChoropleth;

                    // Crop all sources if asset isn't active anymore
                    if (!this.currentAssetActive) {
                        let biggestIndex = Math.max(...[
                            this.allSourcesData.datasets[0].data.findIndex(val => val > 0),
                            this.allSourcesData.datasets[1].data.findIndex(val => val > 0),
                            this.allSourcesData.datasets[2].data.findIndex(val => val > 0),
                            this.allSourcesData.datasets[3].data.findIndex(val => val > 0),
                        ]);

                        // Is there enough data to warrant cropping
                        if (biggestIndex > 15) {
                            if (biggestIndex < this.allSourcesData.labels.length) { biggestIndex++; }
                            this.allSourcesData.labels = this.allSourcesData.labels.slice(0, biggestIndex);
                            this.allSourcesData.datasets[0].data = this.allSourcesData.datasets[0].data.slice(0, biggestIndex);
                            this.allSourcesData.datasets[1].data = this.allSourcesData.datasets[1].data.slice(0, biggestIndex);
                            this.allSourcesData.datasets[2].data = this.allSourcesData.datasets[2].data.slice(0, biggestIndex);
                            this.allSourcesData.datasets[3].data = this.allSourcesData.datasets[3].data.slice(0, biggestIndex);
                        }
                    }

                    // Data for Search Engines graph
                    this.searchEnginesData = data.organiclimited;
                    let searchEnginesDataset = this.searchEnginesData.datasets[0].data;
                    let searchEnginesDatasetNotToday = searchEnginesDataset.slice(0, -1);
                    average = searchEnginesDatasetNotToday.reduce((a, b) => a + b, 0) / searchEnginesDatasetNotToday.length;
                    this.searchEnginesDiff = (Math.round((searchEnginesDataset.at(-1) - average) / average * 100) || 0).toString() + "%";
                    this.searchEnginesData.datasets[0].borderColor = this.searchEnginesDiff.charAt(0) === "-" ? "rgb(256, 0, 0)" : "rgb(50,205,50)";

                    this.cyberlockerData = data.cyberlockerlimited;
                    let cyberlockerDataset = this.cyberlockerData.datasets[0].data;
                    let cyberlockerDatasetNotToday = cyberlockerDataset.slice(0, -1);
                    average = cyberlockerDatasetNotToday.reduce((a, b) => a + b, 0) / cyberlockerDatasetNotToday.length;
                    this.cyberlockerDiff = (Math.round((cyberlockerDataset.at(-1) - average) / average * 100) || 0).toString() + "%";
                    this.cyberlockerData.datasets[0].borderColor = this.cyberlockerDiff.charAt(0) === "-" ? "rgb(256, 0, 0)" : "rgb(50,205,50)";

                    this.torrentData = data.torrentlimited;
                    let torrentDataset = this.torrentData.datasets[0].data;
                    let torrentDatasetNotToday = torrentDataset.slice(0, -1);
                    average = torrentDatasetNotToday.reduce((a, b) => a + b, 0) / torrentDatasetNotToday.length;
                    this.torrentDiff = (Math.round((torrentDataset.at(-1) - average) / average * 100) || 0).toString() + "%";
                    this.torrentData.datasets[0].borderColor = this.torrentDiff.charAt(0) === "-" ? "rgb(256, 0, 0)" : "rgb(50,205,50)";

                    this.socialsData = data.socialslimited;
                    let socialsDataset = this.socialsData.datasets[0].data;
                    let socialsDatasetNotToday = socialsDataset.slice(0, -1);
                    average = socialsDatasetNotToday.reduce((a, b) => a + b, 0) / socialsDatasetNotToday.length;
                    this.socialsDiff = (Math.round((socialsDataset.at(-1) - average) / average * 100) || 0).toString() + "%";
                    this.socialsData.datasets[0].borderColor = this.socialsDiff.charAt(0) === "-" ? "rgb(256, 0, 0)" : "rgb(50,205,50)";

                    this.createDoughnutData();
                    this.$refs.infohashDistribution.createChoropleth(this.infohashChoroplethData);
                })
                .catch(error => {
                    console.error("Can't fetch asset data", error);
                    if ([401, 429].includes(error)) {
                        this.$emitter.emit("logout");
                    }
                    return []
                })
                .finally(() => {
                    this.requestMade = true;
                });
        },

        createDoughnutData() {
            if (this.totalTakedownDoughnutData.datasets[0].data.length === 0) {
                this.totalTakedownDoughnutData.labels = ["No Data"];
                this.totalTakedownDoughnutData.datasets[0].data = [1];
                this.totalTakedownDoughnutData.datasets[0].backgroundColor = ["#BDBDBD"];
                this.totalTakedownDoughnutOptions.plugins.tooltip.enabled = false;
            }

            if (this.cyberlockerDoughnutData.datasets[0].data.length === 0) {
                this.cyberlockerDoughnutData.labels = ["No Data"];
                this.cyberlockerDoughnutData.datasets[0].data = [1];
                this.cyberlockerDoughnutData.datasets[0].backgroundColor = ["#BDBDBD"];
                this.cyberlockerDoughnutOptions.plugins.tooltip.enabled = false;
            }

            if (this.socialsDoughnutData.datasets[0].data.length === 0) {
                this.socialsDoughnutData.labels = ["No Data"];
                this.socialsDoughnutData.datasets[0].data = [1];
                this.socialsDoughnutData.datasets[0].backgroundColor = ["#BDBDBD"];
                this.socialsDoughnutOptions.plugins.tooltip.enabled = false;
            }

            // Pad all doughnut labels to correct length
            let longest = 30;
            this.totalTakedownDoughnutData.labels = this.totalTakedownDoughnutData.labels.map((element) => {
                return element.padEnd(longest, " ")
            });

            this.cyberlockerDoughnutData.labels = this.cyberlockerDoughnutData.labels.map((element) => {
                return element.padEnd(longest, " ")
            });

            this.socialsDoughnutData.labels = this.socialsDoughnutData.labels.map((element) => {
                return element.padEnd(longest, " ")
            });
        },
    },

    watch: {
        assetId: {
            handler() {
                const route = useRoute();
                this.currentAssetId = parseInt(route.params.assetId);
                this.getAssetData();
                this.getGraphData();

                this.isStaff = this.$store.getters.isStaff;
            },
            deep: true,
            immediate: true,
        }
    },
}
</script>

<style scoped>

</style>
