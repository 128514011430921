<template>
<div class="col-12 xl:col-4">
    <div class="graph surface-card shadow-2 border-round p-3 h-full">
        <div class="">
            <div class="flex align-items-center justify-content-between pb-4">
                <span class="text-xl font-medium text-900">Preview of URLs Found</span>
            </div>

            <div v-for="(entry, index) in previewLinks" :key="index" class="">
                <a :href="entry.Link" target="_blank">
                    <div class="text-xl text-600 hover:text-900 text-overflow-ellipsis" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        {{ entry.Link }}
                    </div>
                </a>
                <Divider />
            </div>
            <div>
                <p v-if="previewLinksCount > 5" class="font-bold text-xl text-600">And {{ previewLinksCount - previewLinks.length }} more...</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "UrlPreview",

    props: {
        currentAssetId: {
            type: Number,
            required: true,
        },
    },

    mounted() {
        this.getPreviewLinks();
    },

    data: function() {
        return {
            previewLinks: [],
            previewLinksCount: 0,
        }
    },

    methods: {
        getPreviewLinks() {
            this.$factory.asset.getAssetLinkPreview(this.currentAssetId)
                .then((response) => {
                    this.previewLinks = response.links;
                    this.previewLinksCount = response.total;
                })
                .catch((error) => {
                    console.error("Can't fetch asset data", error);
                    if ([401, 429].includes(error)) {
                        this.$emitter.emit("logout");
                    }
                    return []
                });
        },
    }
}
</script>

<style scoped>
a, u {
    text-decoration: none !important;
    color: inherit !important;
}
</style>
