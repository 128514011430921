<template>
    <div class="flex flex-column flex-auto">
        <div class="px-5 pt-5 pb-0">
            <div id="asset-page" class="grid">
                <!-- Top row -->
                <div class="col-12 pb-0">
                    <div class="grid">
                        <!-- Useful Figures -->
                        <TopFigures :totalPagesSeen="totalPagesSeen" :totalPagesSuccess="totalPagesSuccess"
                                    :totalPagesFiltered="totalPagesFiltered" :totalPeers="totalPeers" :totalTerabytes="totalTerabytes"/>

                        <!-- Asset poster box -->
                        <AssetPosterBox :currentAssetId="currentAssetId" :currentAssetName="currentAssetName" :currentAssetDate="currentAssetDate"
                                        :currentAssetImage="currentAssetImage" :currentAssetType="currentAssetType" :currentAssetSubtype="currentAssetSubtype"
                                        :currentAssetActive="currentAssetActive" :currentAssetAssetHandle="currentAssetAssetHandle"/>
                    </div>
                </div>

                <!-- Second row -->
                <div class="col-12">
                    <div class="grid">
                        <!-- Info Hash Distribution -->
                        <div class="col-12 xl:col-5">
                            <div class="h-full flex flex-column justify-content-start">
                                <InfohashDistribution ref="infohashDistribution" class="hidden sm:block mb-3" choropleth-height="400"/>

                                <TrialContact/>
                            </div>
                        </div>

                        <!-- Info Hash Metrics -->
                        <div class="col-12 xl:col-3">
                            <InfohashMetrics ref="infohashMetrics"/>
                        </div>

                        <!-- URL list -->
                        <UrlPreview :currentAssetId="currentAssetId"/>
                    </div>
                </div>

                <div id="pdf-name" class="col-12 py-0 my-0 flex flex-wrap justify-content-end" style="visibility: hidden">
                    <p class="py-0 my-0 text-600">
                        © Cyber Theft Watch Ltd. {{ new Date().getFullYear() }} All Rights Reserved
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useRoute} from 'vue-router';
import globals from "@/globals";

import AssetPosterBox from "@/components/graphs/asset/AssetPosterBox";
import InfohashDistribution from "@/components/graphs/asset/InfohashDistribution";
import UrlPreview from "@/components/graphs/asset/UrlPreview";
import TopFigures from "@/components/graphs/asset/TopFigures";
import InfohashMetrics from "@/components/graphs/asset/InfohashMetrics";
import TrialContact from "@/components/graphs/asset/TrialContact";


export default {
    name: "AssetTrialInformation",

    components: {
        AssetPosterBox,
        InfohashDistribution,
        UrlPreview,
        TopFigures,
        InfohashMetrics,
        TrialContact
    },

    mounted() {
        this.$emitter.on('export-pdf', () => {
            this.exportPdf();
        });
    },

    data: function() {
        return {
            requestMade: false,
            currentAssetId: 0,
            currentAssetName: "",
            currentAssetDate: "",
            currentAssetImage: "",
            currentAssetType: "",
            currentAssetSubtype: "",
            currentAssetActive: "",
            currentAssetAssetHandle: "",

            allSourcesData: {},
            searchEnginesData: {},
            searchEnginesDiff: "",
            cyberlockerData: {},
            cyberlockerDiff: "",
            torrentData: {},
            torrentDiff: "",
            socialsData: {},
            socialsDiff: "",

            allSourcesTotal: 0,
            allSourcesTotals: {},

            infohashChoroplethData: {},

            totalPagesSeen: 0,
            totalPagesSuccess: 0,
            totalPagesFiltered: 0,
            totalPeers: 0,
            totalTerabytes: 0,

            items: [
                { menu: 'Export png' },
            ],
        }
    },

    methods: {
        getAssetData() {
            let allAssets = this.$store.getters.getActiveAssetData.concat(this.$store.getters.getInactiveAssetData)
            for (let i in allAssets) {
                if (this.currentAssetId === allAssets[i].id) {
                    let currentAsset = allAssets[i];
                    this.currentAssetName = currentAsset.name;
                    this.currentAssetDate = null;
                    this.currentAssetImage = currentAsset.image;
                    this.currentAssetType = currentAsset.type;
                    this.currentAssetSubtype = currentAsset.subtype;
                    this.currentAssetActive = currentAsset.active;
                    this.currentAssetAssetHandle = currentAsset.assetHandle;
                    return;
                }
            }
        },

        getGraphData() {
            if (this.requestMade) {
                console.debug("Avoiding duplicate request to get graph data.")
                return;
            }
            // Get all data and parse it for all graphs on this page
            const requestOptions = {
                method: "GET",
                credentials: 'include',
                headers: new Headers({
                    "Content-Type": "application/json",
                    "XSRF-TOKEN": this.$store.getters.getCSRFToken,
                }),
            };

            console.debug("Current asset id is ", this.currentAssetId);

            let params = new URLSearchParams({
                assetid: this.currentAssetId,
            })

            let uri = globals.host + "/asset/getdata?" + params.toString();

            // Get from DB
            fetch(uri, requestOptions)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    console.debug(data);

                    // Total calculation
                    this.allSourcesTotal = data.allsources.datasets
                        .reduce((a, b) =>
                            a + b.data.reduce((a, b) => a + b, 0), 0
                        );

                    // Data for most graphs
                    this.currentAssetDate = data.startdate;
                    this.allSourcesData = data.allsources;
                    this.infohashChoroplethData = data.infohashChoropleth;

                    this.totalPagesSeen = data.totalpagesseen;
                    this.totalPagesSuccess = data.totalpagessuccess;
                    this.totalPagesFiltered = data.totalpagesfiltered;
                    this.totalPeers = data.totalpeers;
                    this.totalTerabytes = Math.round(data.totalterabytes);

                    // Crop all sources if asset isn't active anymore
                    if (!this.currentAssetActive) {
                        let biggestIndex = Math.max(...[
                            this.allSourcesData.datasets[0].data.findIndex(val => val > 0),
                            this.allSourcesData.datasets[1].data.findIndex(val => val > 0),
                            this.allSourcesData.datasets[2].data.findIndex(val => val > 0),
                            this.allSourcesData.datasets[3].data.findIndex(val => val > 0),
                        ]);

                        // Is there enough data to warrant cropping
                        if (biggestIndex > 15) {
                            if (biggestIndex < this.allSourcesData.labels.length) { biggestIndex++; }
                            this.allSourcesData.labels = this.allSourcesData.labels.slice(0, biggestIndex);
                            this.allSourcesData.datasets[0].data = this.allSourcesData.datasets[0].data.slice(0, biggestIndex);
                            this.allSourcesData.datasets[1].data = this.allSourcesData.datasets[1].data.slice(0, biggestIndex);
                            this.allSourcesData.datasets[2].data = this.allSourcesData.datasets[2].data.slice(0, biggestIndex);
                            this.allSourcesData.datasets[3].data = this.allSourcesData.datasets[3].data.slice(0, biggestIndex);
                        }
                    }

                    this.$refs.infohashDistribution.createChoropleth(this.infohashChoroplethData);
                    this.$refs.infohashMetrics.fetchInfohashMetrics(this.currentAssetId);
                })
                .catch(error => {
                    console.error("Can't fetch asset data", error);
                    if ([401, 429].includes(error)) {
                        this.$emitter.emit("logout");
                    }
                    return []
                })
                .finally(() => {
                    this.requestMade = true;
                });
        },

        async exportPdf() {
            console.debug('Export PDF');
            await this.$htmlToPaper('asset-page');
        },
    },

    watch: {
        assetId: {
            handler() {
                const route = useRoute();
                this.currentAssetId = parseInt(route.params.assetId);
                this.getAssetData();
                this.getGraphData();
            },
            deep: true,
            immediate: true,
        }
    },
}
</script>

<style scoped>

</style>
