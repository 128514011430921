<template>
<div class="graph surface-card shadow-2 border-round p-3">
    <div class="">
        <div class="flex align-items-center justify-content-between">
            <span class="text-xl font-medium text-900">Torrent Activity by Country</span>
        </div>

        <div class="flex align-items-center flex-shrink-0 mt-3">
            <canvas id="choropleth" :height="choroplethHeight"></canvas>
        </div>
    </div>
</div>
</template>

<script>
import countriesTopoJson from "@/assets/maps/countries-50m.json"
import {ChoroplethController, ColorScale, GeoFeature, ProjectionScale, topojson} from "chartjs-chart-geo";
import {CategoryScale, Chart, Tooltip} from "chart.js";

Chart.register([Tooltip])
Chart.register(CategoryScale);
Chart.register(ChoroplethController, GeoFeature, ColorScale, ProjectionScale);

export default {
    name: "InfohashDistribution",

    props: {
        'choroplethHeight': {
            type: Number,
            default: 400
        },
    },

    data() {
        return {
            chart: null,
            infohashChoroplethData: null,
        }
    },

    mounted() {
        this.$emitter.on('infohash-choropleth', () => {
            this.destroyChart();
            this.createChoropleth(this.infohashChoroplethData);

            setTimeout(() => {
                this.$emitter.emit('infohash-choropleth-loaded')
            }, 200);
        });
    },

    methods: {
        destroyChart() {
            if (this.chart !== null) {
                this.chart.destroy();
            }
        },

        createChoropleth(infohashChoroplethData, attempt = 0) {
            this.infohashChoroplethData = infohashChoroplethData;

            let countries = topojson.feature(countriesTopoJson, countriesTopoJson.objects.countries).features;

            let canvas = document.getElementById("choropleth");
            let ctx = canvas.getContext("2d");

            // Get values from api dictionary
            let labels = [];
            let data = [];
            for (let infohashCountry in infohashChoroplethData.labels) {
                for (let countryData in countries){
                    if (infohashChoroplethData.labels[infohashCountry] === countries[countryData].properties.iso_a2) {
                        labels.push(countries[countryData].properties.name)
                        data.push({feature: countries[countryData], value: infohashChoroplethData.dataset[infohashCountry]});
                    }
                }
            }

            try {
                this.chart = new Chart(ctx, {
                    type: 'choropleth',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: 'Countries',
                            data: data,
                            outline: countries,
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        showOutline: true,
                        showGraticule: false,
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        let countryName = context.dataset.data[context.dataIndex].feature.properties.name;
                                        let label = countryName + ": " + (Math.round(context.formattedValue * 10) / 10) + "%" || '';
                                        return label;
                                    }
                                }
                            }
                        },
                        scales: {
                            xy: {
                                projection: 'geoMercator'
                            },
                            color: {
                                display: false,
                                interpolate: function (value) {
                                    let value_rounded = value.toFixed(1);
                                    if (value_rounded === "0.0") {
                                        return "#92C5F2"
                                    } else if (value_rounded === "0.3") {
                                        return "#75B6EF"
                                    } else if (value_rounded === "0.5") {
                                        return "#58A8ED"
                                    } else if (value_rounded === "0.7") {
                                        return "#3B97E8"
                                    } else if (value_rounded === "1.0") {
                                        return "#1F88E5"
                                    }
                                },
                                quantize: 5,
                                legend: {
                                    display: true,
                                    enabled: false,
                                    position: 'bottom-left',
                                    align: 'left'
                                },
                                ticks: {
                                    callback: function (value) {
                                        return value + "%";
                                    }
                                }
                            },
                        }
                    }
                });
            } catch (e) {
                console.error(e);

                if (attempt > 3) {
                    console.error("Failed to create choropleth chart after 3 attempts");
                    this.$emitter.emit('bad-toast', 'Failed to create PDF. Please try again later.')
                    return;
                }

                // Retry creating the chart with a delay
                setTimeout(() => {
                    this.destroyChart();
                    this.createChoropleth(infohashChoroplethData, attempt + 1);
                }, 500);
            }
        },
    }
}
</script>

<style scoped>

</style>
