<template>
<div class="graph surface-card shadow-2 border-round p-3 h-full">
    <div class="">
        <div class="flex align-items-center justify-content-between">
            <span class="text-xl font-medium text-900">Get in Touch</span>
        </div>

        <div class="flex align-items-center flex-shrink-0 mt-3">
            <div class="flex flex-column text-xl text-600">
                <span class="text-3xl">James@cybertheftwatch.com</span>
                <span class="text-3xl">+44 7413 088898</span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "TrialContact"
}
</script>

<style scoped>

</style>
