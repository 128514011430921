<template>
    <AssetInformation v-if="!currentAssetType.includes('Prospect')"/>
    <AssetTrialInformation v-else/>
</template>

<script>
import {useRoute} from 'vue-router';
import AssetInformation from "@/components/pages/AssetInformation";
import AssetTrialInformation from "@/components/pages/AssetTrialInformation";

export default {
    name: "Asset",
    components: {
        AssetInformation,
        AssetTrialInformation,
    },

    data: function() {
        return {
            currentAssetId: null,

            currentAssetType: "",
            currentAssetSubtype: "",
        }
    },

    mounted() {
        this.currentAssetId = this.$route.params.assetId;
        this.checkAssetType();
        console.debug(this.currentAssetId, this.currentAssetType, this.currentAssetSubtype)
    },


    methods: {
        checkAssetType() {
            let allAssets = this.$store.getters.getActiveAssetData.concat(this.$store.getters.getInactiveAssetData)
            for (let i in allAssets) {
                if (this.currentAssetId === allAssets[i].id) {
                    let currentAsset = allAssets[i];
                    this.currentAssetType = currentAsset.type;
                    this.currentAssetSubtype = currentAsset.subtype;
                    return;
                }
            }
        },
    },

    watch: {
        assetId: {
            handler() {
                const route = useRoute();
                this.currentAssetId = parseInt(route.params.assetId);
                this.checkAssetType();
            },
            deep: true,
            immediate: true,
        }
    },
}

</script>

<style>
</style>
