<template>
<div class="col-12 xl:col-4">
    <div class="graph surface-card shadow-2 border-round p-3 mb-3 h-full flex align-content-center">
        <div class="flex flex-row w-full h-full">
            <div class="flex align-content-center justify-content-start">
                <div v-if='this.currentAssetName === ""'>
                    <Skeleton width="20rem" height="4rem" borderRadius="16px"></Skeleton>
                </div>
                <div v-else class="flex align-content-center justify-content-center">
                    <img v-if="!currentAssetType.includes('Prospect')" class="mt-1 border-round" v-bind:src="currentAssetImage">
                    <img v-else class="mt-1 border-round" v-bind:src="currentAssetImage">
                </div>
            </div>

            <!--Put download button in top right-->
            <div class="w-full h-full flex flex-column" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                <div class="flex justify-content-end">
                    <div v-if='this.currentAssetName === ""'>
                        <Skeleton width="20rem" height="4rem" borderRadius="16px"></Skeleton>
                    </div>
                    <div v-else>
                        <i v-if="isStaff && this.$store.getters.getUserRoles.includes('admin')" id="edit-asset-wrench" class="pi pi-cog text-base xl:text-2xl mr-3 hidden lg:inline" @click="editAsset" style="visibility: visible"></i>
                        <i v-if="isStaff && this.$store.getters.getUserRoles.includes('admin')" id="export-asset-system" class="pi pi-file-pdf text-base xl:text-2xl mr-3 hidden lg:inline" @click="startPDF" style="visibility: visible"></i>
                        <i id="export-asset" class="pi pi-envelope text-base xl:text-2xl" @click="requestPDF" style="visibility: visible"></i>
                    </div>
                </div>


                <div class="pl-4 h-full w-full flex flex-column" >
                    <span class="text-600 pt-0 asset-info text-overflow-ellipsis" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">Name: {{ this.currentAssetName }}</span>
                    <span class="text-600 asset-info">Type: {{ this.currentAssetType.replace(' Prospect', '') }}</span>
                    <span class="text-600 asset-info">Start Date: {{ this.currentAssetDate }}</span>
                    <span class="text-600 asset-info">Imdb: {{this.currentAssetAssetHandle}}</span>
                    <span class="text-600 asset-info">Active: {{ this.currentAssetActive }}</span>
                </div>
            </div>

        </div>
    </div>
</div>
<AssetEditSimple ref="assetEdit"/>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import globals from "@/globals";
import AssetEditSimple from "@/components/modals/AssetEditSimple";


export default {
    name: "AssetPosterBox",

    components: {
        AssetEditSimple,
    },

    props: {
        currentAssetId: {
            type: Number,
            required: true,
        },
        currentAssetName: {
            type: String,
            required: true,
        },
        currentAssetDate: {
            type: String,
            required: true,
        },
        currentAssetImage: {
            type: String,
            required: true,
        },
        currentAssetType: {
            type: String,
            required: true,
        },
        currentAssetSubtype: {
            type: String,
            required: true,
        },
        currentAssetActive: {
            type: String,
            required: true,
        },
        currentAssetAssetHandle: {
            type: String,
            required: true,
        },
    },

    data: function() {
        return {
            isStaff: this.$store.getters.isStaff,

            oldWidth: "",
            elementExportAsset: null,
            elementExportSystem: null,
            elementEditAsset: null,
            elementPDFName: null,
            elementPageWrapper: null,
        }
    },

    mounted() {
        this.$emitter.on('infohash-choropleth-loaded', () => {
            this.downloadPDF();
        });
    },

    methods: {
        startPDF() {
            this.removeShadow();
            this.scalePage();

            this.elementExportAsset = document.getElementById("export-asset");
            this.elementExportAsset.style.visibility = "hidden";
            this.elementExportSystem = document.getElementById("export-asset-system");
            this.elementExportSystem.style.visibility = "hidden";
            this.elementEditAsset = document.getElementById("edit-asset-wrench");
            this.elementEditAsset.style.visibility = "hidden";
            this.elementPDFName = document.getElementById("pdf-name");
            this.elementPDFName.style.visibility = "visible";

            this.elementPageWrapper = document.getElementById("asset-page");
            this.elementPageWrapper.style.backgroundColor = getComputedStyle(document.body).getPropertyValue('--surface-ground');

            this.oldWidth = this.elementPageWrapper.style.width;

            if (this.currentAssetType.includes('Prospect')) {
                // this.elementPageWrapper.style.width = "1350px";
                this.elementPageWrapper.style.width = "1300px";
            } else {
                this.elementPageWrapper.style.width = "1470px";
            }

            this.$emitter.emit('infohash-choropleth')
        },

        downloadPDF() {
            html2canvas(this.elementPageWrapper, { allowTaint: true, useCORS: true }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF("l", "mm", "a4");
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                // add image to pdf
                pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight, 'main', 'FAST');

                // fill rest of page with colour
                pdf.setFillColor(getComputedStyle(document.body).getPropertyValue('--surface-ground'));
                pdf.rect(0, pdfHeight, pdfWidth, pdf.internal.pageSize.getHeight() - pdfHeight, 'F');

                pdf.save("download.pdf");

                // add shadow-2 class to all graphs
                this.addShadow()

                this.elementExportAsset.style.visibility = "visible";
                this.elementExportSystem.style.visibility = "visible";
                this.elementEditAsset.style.visibility = "visible";
                this.elementPDFName.style.visibility = "hidden";

                window.resizeTo(this.screenWidth, this.screenHeight);
            });

            this.elementPageWrapper.style.width = this.oldWidth;
        },

        requestPDF() {
            const requestOptions = {
                method: "POST",
                credentials: 'include',
                headers: new Headers({
                    "Content-Type": "application/json",
                    "XSRF-TOKEN": this.$store.getters.getCSRFToken,
                }),
                body: JSON.stringify({
                    "items": this.currentAssetId,
                })
            };

            let uri = globals.host + "/export/assets";

            // Get from DB
            fetch(uri, requestOptions)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    console.debug(data);
                    this.$emitter.emit("good-toast", 'Export is in progress, please check your email in a few minutes');

                })
                .catch(error => {
                    this.$emitter.emit("bad-toast", 'Error - Data can not be exported, please try again later');
                    console.error('There was an error exporting!', error);
                    if ([401, 429].includes(error)) {
                        this.$emitter.emit("logout");
                    }
                    return []
                });
        },

        removeShadow() {
            let graphs = document.getElementsByClassName("graph");
            for (let i = 0; i < graphs.length; i++) {
                graphs[i].classList.remove("shadow-2");
            }
        },

        addShadow() {
            let graphs = document.getElementsByClassName("graph");
            for (let i = 0; i < graphs.length; i++) {
                graphs[i].classList.add("shadow-2");
            }
        },

        scalePage() {
            // get current screen resolution
            this.screenWidth = window.screen.availWidth;
            this.screenHeight = window.screen.availHeight;
            window.resizeTo(this.screenWidth, this.screenHeight);
        },

        editAsset() {
            this.$refs.assetEdit.show(this.currentAssetId);
        },
    }
}
</script>

<style scoped>
img {
    object-fit: cover;
    aspect-ratio: 0.675;
    height: auto;
    min-width: 180px;
    max-width: 15vw;
    width: 100%;
}

.asset-info {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.25rem;
}
</style>
