<template>
<div class="col-12 xl:col-4">
    <!-- Takedown Total Overview -->
    <div class="graph surface-card shadow-2 border-round p-3 mb-3">
        <div class="">
            <div class="flex align-items-center justify-content-between mb-4">
                <span class="text-xl font-medium text-900">Total DMCA Takedowns</span>
            </div>
            <div v-if='this.totalTakedownDoughnutData.datasets[0].data.length === 0'
                 class="mt-3 list-none p-0 mx-0">
                <Skeleton width="20rem" height="4rem" borderRadius="16px"></Skeleton>
            </div>
            <div v-else class="mt-3 list-none p-0 mx-0 flex">
                <Chart class="w-full" type="doughnut" :data="totalTakedownDoughnutData" :options="totalTakedownDoughnutOptions"/>
            </div>
        </div>
    </div>

    <!-- Cyberlocker Total Breakdown -->
    <div class="graph surface-card shadow-2 border-round p-3 mb-3">
        <div class="">
            <div class="flex align-items-center justify-content-between mb-4">
                <span class="text-xl font-medium text-900">Cyberlocker Removals</span>
            </div>
            <div v-if='this.cyberlockerDoughnutData.datasets[0].data.length === 0'
                 class="mt-3 list-none p-0 mx-0">
                <Skeleton width="20rem" height="4rem" borderRadius="16px"></Skeleton>
            </div>
            <div v-else class="mt-3 list-none p-0 mx-0 flex">
                <Chart class="w-full" type="doughnut" :data="cyberlockerDoughnutData" :options="cyberlockerDoughnutOptions"/>
            </div>
        </div>
    </div>

    <!-- Socials Total Breakdown -->
    <div class="graph surface-card shadow-2 border-round p-3">
        <div class="">
            <div class="flex align-items-center justify-content-between mb-4">
                <span class="text-xl font-medium text-900">Social Removals</span>
            </div>
            <div v-if='this.socialsDoughnutData.datasets[0].data.length === 0'
                 class="mt-3 list-none p-0 mx-0">
                <Skeleton width="20rem" height="4rem" borderRadius="16px"></Skeleton>
            </div>
            <div v-else class="mt-3 list-none p-0 mx-0 flex">
                <Chart class="w-full" type="doughnut" :data="socialsDoughnutData" :options="socialsDoughnutOptions"/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "DoughnutStack",

    props: {
        totalTakedownDoughnutData: {
            type: Object,
            required: true,
        },
        totalTakedownDoughnutOptions: {
            type: Object,
            required: true,
        },
        cyberlockerDoughnutData: {
            type: Object,
            required: true,
        },
        cyberlockerDoughnutOptions: {
            type: Object,
            required: true,
        },
        socialsDoughnutData: {
            type: Object,
            required: true,
        },
        socialsDoughnutOptions: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style scoped>

</style>
