<template>
<div class="col-12 xl:col-8">
    <div class="graph surface-card shadow-2 border-round p-3 mb-3 h-full">
        <div class="flex align-items-center justify-content-between">
            <span class="text-xl font-medium text-900">Piracy since activation</span>
            <div class="ml-8">
                <span class="text-900 font-medium text-xl">Takedowns: {{ allSourcesTotal }}</span>
            </div>
        </div>
        <div v-if="Object.keys(this.allSourcesData).length !== 0" class="mt-3 h-full flex flex-column align-content-end align-items-end ">
            <div class="line-chart h-full w-full bottom-0" style="max-height: 300px">
                <Chart type="line" :data="allSourcesData" :options="allSourcesGraphOptions" class="h-full bottom-0" :height="60"/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "PiracySinceActivation",

    props: {
        allSourcesTotal: {
            type: Number,
            required: true,
        },
        allSourcesData: {
            type: Object,
            required: true,
        },
    },

    data: function() {
        return {
            allSourcesGraphOptions: {
                adaptive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        labels : {
                            boxHeight: 2,
                            boxWidth: 30,
                        }
                    },
                },
                scales:{
                    x: {
                        ticks: {
                            maxRotation: 0
                        }
                    },
                    y: {
                        beginAtZero: true,
                    }
                },
                elements: {
                    point: {
                        pointRadius: 0,
                    },
                    line: {
                        tension: 0.4,
                        borderWidth: 2,
                    },
                },
                layout: {
                    padding: {
                        top: 3,
                        bottom: 3
                    }
                }
            },
        }
    }
}
</script>

<style scoped>
</style>
