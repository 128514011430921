<template>
<div class="col-12 xl:col-8 py-0 m-0">
    <div class="grid pt-2 h-full">
        <div class="col-6 flex flex-column justify-content-between h-full pb-0 mb-2">
            <div class="h-full graph surface-card shadow-2 border-round p-3 mb-2">
                <div class="flex align-items-center justify-content-between">
                    <span class="text-xl font-medium text-900">Total Infringing Pages</span>
                </div>
                <div class="flex align-items-center flex-shrink-0 mt-6 text-5xl sm:text-6xl text-600">
                    <AnimatedNumber :number="totalPagesSuccess"/>
                </div>
            </div>

            <div class="h-full graph surface-card shadow-2 border-round p-3 mt-2">
                <div class="flex align-items-center justify-content-between">
                    <span class="text-xl font-medium text-900">Total Torrent Activity</span>
                </div>
                <div class="flex align-items-center flex-shrink-0 mt-6 text-5xl sm:text-6xl text-600">
                    <AnimatedNumber :number="totalPeers"/>
                </div>
            </div>
        </div>

        <div class="col-6 flex flex-column justify-content-between h-full pb-0 mb-2">
            <div class="h-full graph surface-card shadow-2 border-round p-3 mb-2">
                <div class="flex align-items-center justify-content-between">
                    <span class="text-xl font-medium text-900">Total Pages Processed</span>
                </div>
                <div class="flex align-items-center flex-shrink-0 mt-6 text-5xl sm:text-6xl text-600">
                    <AnimatedNumber :number="totalPagesSeen"/>
                </div>
            </div>

            <div class="h-full graph surface-card shadow-2 border-round p-3 mt-2">
                <div class="flex align-items-center justify-content-between">
                    <span class="text-xl font-medium text-900">Terabytes of Data Shared</span>
                </div>
                <div class="flex align-items-center flex-shrink-0 mt-6 text-5xl sm:text-6xl text-600">
                    <AnimatedNumber :number="totalTerabytes"/>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import AnimatedNumber from "@/components/elements/AnimatedNumber";

export default {
    name: "TopFigures",

    components: {
        AnimatedNumber
    },

    props: {
        'totalPagesSeen': {
            type: Number,
            default: 0
        },
        'totalPagesSuccess': {
            type: Number,
            default: 0
        },
        'totalPagesFiltered': {
            type: Number,
            default: 0
        },
        'totalPeers': {
            type: Number,
            default: 0
        },
        'totalTerabytes': {
            type: Number,
            default: 0
        }
    },
}
</script>

<style scoped>

</style>
