<template>
<div class="graph surface-card shadow-2 border-round p-3 mb-3 h-full">
    <div class="flex align-items-center justify-content-between pb-4">
        <span class="text-xl font-medium text-900">Top 10 Infringing Countries</span>
    </div>
    <div v-for="(entry, index) in infohashMetrics" :key="index">
        <div class="flex align-items-center justify-content-between">
            <div>
                <span class="text-xl font-medium text-600 emoji-flag pr-2">{{ entry.icon }}</span>
                <span class="text-xl font-medium text-600">{{ entry.name }}</span>
            </div>

            <span class="text-xl font-medium text-600">{{ entry.percent }} %</span>
        </div>
        <Divider />
    </div>
</div>
</template>

<script>
import emojiFlags from "../../../assets/data/emoji-flags.json"

export default {
    name: "InfohashMetrics",

    data: function() {
        return {
            infohashMetrics: [],
            emojiFlags: emojiFlags
        }
    },

    mounted() {
        this.fetchInfohashMetrics();
    },

    methods: {
        fetchInfohashMetrics(assetId) {
            if (assetId === undefined)
                return;

            this.$factory.asset.getAssetInfohashMetrics(assetId)
                .then(response => {
                    this.infohashMetrics = response.data;

                    console.debug("Infohash metrics", response);
                    this.combineFlagWithData();
                })
                .catch(error => {
                    console.log(error);
                });
        },

        combineFlagWithData() {
            // Iterate over all infohashMetrics and add the flag to the countrycode
            // The flags are not in the same order as the infohashMetrics
            for (let i = 0; i < this.infohashMetrics.length; i++) {
                for (let j = 0; j < this.emojiFlags.length; j++) {
                    if (this.infohashMetrics[i].countrycode === this.emojiFlags[j].ISO) {
                        this.infohashMetrics[i].icon = this.countryFlag(this.infohashMetrics[i].countrycode)
                        this.infohashMetrics[i].name = this.emojiFlags[j].Name;
                    }
                }
            }

            console.debug("Infohash metrics with flags", this.infohashMetrics)
        },

        countryFlag(countryCode) {
            return countryCode.toUpperCase().replace(/./g, char =>
                String.fromCodePoint(127397 + char.charCodeAt())
            );
        }
    }
}
</script>

<style scoped>
</style>
